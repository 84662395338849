@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aboreto:wght@100;400;700&display=swap');

// breakpoints
$breakpoint_s: 500px;
$breakpoint_m: 767px;
$breakpoint_l: 1020px;
$breakpoint_xl: 1450px;

@mixin breakpoint($breakpoint) {
	@media ( max-width: $breakpoint){ @content; }
}

body,html{
    width: 100%;

    margin: 0;
    padding: 0;
    // overflow-x: hidden;
}

body{
    &.fixed{
        height: 100vh;
        overflow: hidden;
    }
}

.app {
    width: 100%;
	min-height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;

    .main{
        padding: 20px;
        font-family: 'Urbanist', sans-serif;

        margin: 0 auto;
        width: calc( 50% - 40px );

        @include breakpoint( $breakpoint_l ) {
            width: calc( 80% - 40px );
        }
        @include breakpoint( $breakpoint_m ) {
            width: calc( 100% - 40px );
        }
    }

    .hidden{
        display: none;
    }

    // website title
    .header{

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 50vh;

        @include breakpoint( $breakpoint_m ) {
            height: 50vh;
            justify-content: flex-start;
        }

        &__title{
            margin: 0;
            font-size: 2.488em;
            font-weight: 700;
            font-family: 'Aboreto';
            // text-transform: uppercase;
        }
        &__subtitle{
            margin: 20px 0 0 0;
            font-size: 1.2em;
            line-height: 1.3em;
            font-weight: 400;
        }
    }

    .footer{

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10vh 0 0px 0;

        &__item{
            margin: 5px 0 0 0;
            font-size: 0.8em;
            line-height: 1.4em;
            font-weight: 400;
        }

        a{
            font-weight: 700;
            color: #0947aa;
        }

    }

    // single gallery
    .items{

        .location{
            margin: 0 0 20px 0;
            transition: all .1s ease-in-out;

            &__head{
                position: -webkit-sticky;
                position: -moz-sticky;
                position: -ms-sticky;
                position: -o-sticky;
                position: sticky;

                z-index: 999;
                top: 0;
                // margin: 0 0 20px 0;
                padding: 10px 20px;
                background: #f7f7f7;
                transition: all .1s ease-in-out;

                &.sticky{
                    &:hover aside{
                        // display: flex !important;
                    }
                    aside{
                        display: none !important;
                    }
                }

                // gallery title
                h2{
                    margin: 0;
                    font-size: 1.44em;
                    font-weight: 400;
                    
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    cursor: pointer;

                    transition: all .1s ease-in-out;

                    label{
                        display: flex;
                        align-items: center;
                        pointer-events: none;

                        font-family: 'Aboreto';
                    }

                    svg{
                        width: 16px;
                        height: 16px;
                        margin: 0 25px 0 -6px;
                        transition: all .1s ease-in-out;
                    }

                    span{
                        margin: 0 0 0 20px;
                        font-size: .55em;
                        opacity: .25;
                        transition: all .1s ease-in-out;
                    }
                }
                // gallery subtitle
                aside{
                    margin: 15px 0 15px 35px;

                    display: flex;
                    justify-content: space-between;

                    font-size: 0.9em;
                    line-height: 1.4em;
                    transition: all .2s ease-in-out;

                    label{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    span{
                        margin: 0 10px;
                        text-align: right;
                        
                        a{
                            height: 100%;
                            display: flex;
                            align-items: center;

                            padding: 5px;
                            color: #222;
                            font-size: 1em;
                            text-decoration: none;
                            text-transform: uppercase;

                            svg{
                                font-size: 1.44em;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }

            &__items{
                // 
            }

            aside,
            .location__items{
                display: none;
            }

            &:hover{

                h2{
                    svg{
                        width: 24px;
                        height: 24px;
                        margin: 0 21px 0 -10px;
                    }
                    span{
                        opacity: 1;
                    }
                }
            }

            &.active{

                .location__head{
                    border-bottom: 1px solid #ddd;
                }

                aside{
                    display: flex;
                }

                h2{
                    svg{
                        width: 24px;
                        height: 24px;
                        margin: 0 18px 0 -7px;
                    }
                    span{
                        opacity: 1;
                    }
                }

                .location__items{
                    display: block;
                }
            }
        }

        

        // gallery image

        .item{
            margin: 0 0 20px 0;

            img{
                display: block;
                margin: 0;
                width: 100%;
            }

            &__details{
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                &-preview{
                    font-size: 0.8em;
                }
                &-pricing{
                    font-size: 0.8em;
                }
                &-pagination{
                    padding: 6px 22px 0 0;
                    font-size: 0.7em;
                    opacity: .25;
                }
            }

            .preview{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin: 0 0 20px 0;

                button{
                    position: absolute;
                    top: -36px;
                    left: 2px;
                    border: none;
                    transition: all .1s ease-in-out;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 10px;
                    // width: 32px;
                    height: 34px;

                    background: #fff;
                    color: #222;

                    cursor: pointer;

                    &:hover{
                        background: #fff;
                    }

                    svg{
                        width: 24px;
                        height: 24px;
                        transition: all .2s ease-in-out;
                    }
                    small{
                        margin: 0 0 0 5px;
                    }
                }

                .preview-modal{
                    display: none;
                }

                &.active{

                    .preview-modal{
                        z-index: 1000;
                        display: block;
                        position: fixed;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: #fff;
                        padding: 20px;

                        &__wrapper{
                            overflow-y: auto;
                            height: 100%;
                        }

                        button{
                            top: 0;
                            right: 0;
                            left: auto;
                            background-color: #222;
                            color: #fff;
                        }
                    }

                }
            }

            .pricings{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin: 0 0 20px 0;

                &.active{
                    button{
                        background-color: #fff;
                        svg{
                            
                            // transform: rotate(180deg);
                        }
                    }
                    .pricing{
                        display: flex;
                    }
                }

                button{
                    position: absolute;
                    top: -36px;
                    right: 2px;
                    border: none;
                    transition: all .1s ease-in-out;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 10px;
                    // width: 32px;
                    height: 34px;

                    background: #fff;
                    color: #222;

                    cursor: pointer;

                    &:hover{
                        background: #fff;
                    }

                    svg{
                        width: 24px;
                        height: 24px;
                        transition: all .2s ease-in-out;
                    }
                    small{
                        margin: 0 0 0 5px;
                    }
                }

                .pricing{
                    width: 100%;
                    display: none;
                    align-items: flex-start;
                    justify-content: space-between;

                    background: #fff;
                    color: #222;

                    margin: 10px 10px 0 0;
                    padding: 7px 10px;

                    font-weight: 400;
                    font-size: 1.2em;
                    transition: all .1s ease-in-out;

                    border: 2px solid #222;

                    text-decoration: none;

                    &:hover{
                        cursor: pointer;
                        background: #222;
                        color: #fff;
                    }

                    label{
                        font-weight: 400;
                        margin: 0 10px 0 0;
                    }
                    data{
                        font-weight: 700;
                    }
                }
            }
            
        }
    }

    .loader{
        margin: auto; 
        // background: rgb(241, 242, 243); 
        display: block; 
        shape-rendering: auto;

        path{
            transform:scale(0.54);
            transform-origin:50px 50px;
        }
    }
}